import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import CardToPage from "../elements/card-to-page";

const LinkCard = ({ data }) => {
  const { title, cardTitle } = data;
  return (
    <section className="section link-card wrapper-container px-0">
      <div className="row mx-0">
        <div className="col-md-12 align-center">
          {title?.value && <h2 className="section__title">{title.value}</h2>}
        </div>
      </div>
      <CardToPage cards={cardTitle} />
    </section>
  );
};

export default withPreview(LinkCard);
export const fragment = graphql`
  fragment CardPageLinkFragment on LiferayWebContent {
    ... on LiferayJskLayoutPaginaProdotto {
      link
    }
    ... on LiferayJskLayoutPaginaHub {
      link
    }
    ... on LiferayJskLayoutPaginaGenerica {
      link
    }
    ... on LiferayJskLayoutLandingOfferte {
      link
    }
  }
  fragment JskLinkCardFragment on LiferayJskLinkCard {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    cardTitle {
      value
      content {
        cardImage {
          node {
            gatsbyImageData
          }
          value {
            description
          }
        }
        page {
          value {
            ...CardPageLinkFragment
          }
        }
        link {
          value
        }
      }
    }
  }
`;
