import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";
import Icon from "../elements/icon-svg";

const IconBox = ({ data }) => {
  const { title, icon, cta } = data;
  return (
    <section className="section icon-box wrapper-container px-0">
      <div className="row mx-0">
        <div className="col-md-12 align-center">
          {title?.value && <h2 className="section__title">{title.value}</h2>}
        </div>
      </div>
      <div className="row mx-0 justify-content-center">
        <div className="col-md-12">
          <div className="icon-box__container">
            <div className="icon-box__icons">
              {icon?.map((item, key) => (
                <div key={key} className="icon-box__icon">
                  <GatsbyImage
                    image={getImage(item?.node)}
                    alt={item.content?.description?.value || ""}
                    className="icon-box__image"
                  />
                  {item?.content?.label?.value && (
                    <span className="icon-box__label">{item.content.label.value}</span>
                  )}
                </div>
              ))}
            </div>
            <div className="icon-box__link">
              <Link
                to={cta?.content?.link?.value}
                title={cta?.value}
                className="cta-link w-100 px-4 py-2 text-center text-nowrap my-1 my-md-0"
              >
                {cta?.value && <span className="icon-box__cta-text">{cta.value}</span>}
                <Icon name="open" cls="icon-box__open" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(IconBox);
export const fragment = graphql`
  fragment JskIconBoxFragment on LiferayJskIconBox {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    icon {
      node {
        gatsbyImageData
      }
      content {
        label {
          value
        }
      }
    }
    cta {
      value
      content {
        link {
          value
        }
      }
    }
  }
`;
