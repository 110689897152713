import React, { useState } from "react";
import classNames from "classnames";
import Icon from "../elements/icon-svg";

const Disclosure = ({ disclosure, secondary }) => {
  const [open, setOpen] = useState(false);

  return (
    <li
      className={classNames("accordion-list__item", {
        "accordion-list__item--secondary": secondary,
      })}
    >
      <button
        className={classNames("accordion-list__title", {
          "accordion-list__title--secondary": secondary,
        })}
        onClick={() => setOpen((open) => !open)}
      >
        {disclosure?.value && (
          <span className="accordion-list__disclosure">{disclosure.value}</span>
        )}
        <Icon
          name={
            secondary
              ? open
                ? "chevron-up"
                : "chevron-down"
              : open
              ? "chevron-up-blue"
              : "chevron-down-blue"
          }
          cls={classNames("accordion-list__icon", { "accordion-list__icon--secondary": secondary })}
        />
      </button>
      <div
        className={classNames("accordion-list__content", {
          "accordion-list__content--expanded": open,
          "accordion-list__content--secondary": secondary,
        })}
      >
        {disclosure?.content?.body?.value ? (
          <div
            dangerouslySetInnerHTML={{
              __html: disclosure.content.body.value,
            }}
          />
        ) : (
          <ul className="accordion-list__list">
            {disclosure?.content?.secondSection?.map((secondDisclosure, i) => (
              <Disclosure disclosure={secondDisclosure} secondary key={i} />
            ))}
          </ul>
        )}
      </div>
    </li>
  );
};

export default Disclosure;
