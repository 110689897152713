const FORM_AUTOLETTURA_ENDPOINT = process.env.GATSBY_FORM_AUTOLETTURA_ENDPOINT;

export const submitFormAutolettura = async (values) => {
  const { codiceFiscale, codiceCliente, autoLettura, personalData, grecaptcha, privacyCheck } =
    values;
  const fetchUrl = FORM_AUTOLETTURA_ENDPOINT;
  const dataParams = new URLSearchParams();
  dataParams.append("codiceFiscale", codiceFiscale ? codiceFiscale : null);
  dataParams.append("codiceCliente", codiceCliente ? codiceCliente : null);
  dataParams.append("autoLettura", autoLettura ? autoLettura : null);
  dataParams.append("privacy", privacyCheck ? (personalData ? true : false) : true);
  dataParams.append("grecaptcha", grecaptcha ? grecaptcha : null);
  const res = await fetch(fetchUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: dataParams,
  });
  if (!res.ok) {
    throw new Error(`Error: ${res.status} ${res.statusText}`);
  }
  const result = await res.json();
  if (result.success === true && result.message === "saved") {
    return result;
  } else {
    throw new Error(`Form response not ok`);
  }
};
