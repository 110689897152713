import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";

const TextBox = ({ data }) => {
  const { title, header } = data;
  return (
    <section className="section text-box wrapper-container px-0">
      {title?.value && (
        <div className="row mx-0">
          <div className="col-md-12 align-center">
            <h2 className="section__title">{title.value}</h2>
          </div>
        </div>
      )}
      <div className="row mx-0 justify-content-center">
        <div className="col-md-12">
          <div className="text-box__container">
            {header?.map((item, key) => (
              <div key={key} className="text-box__element">
                {item?.value && <span className="text-box__header">{item.value}</span>}
                {item?.content?.text?.value && (
                  <div
                    className="text-box__text"
                    dangerouslySetInnerHTML={{ __html: item.content.text.value }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(TextBox);
export const fragment = graphql`
  fragment JskTextBoxFragment on LiferayJskTextBox {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    header {
      value
      content {
        text {
          value
        }
      }
    }
  }
`;
