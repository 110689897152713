import React, { useState, useRef, useEffect } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import withPreview from "../../utility/with-preview";
import AccordionFaq from "../elements/accordion-faq";
import Slider from "react-slick";
import { PrevArrow, NextArrow } from "../elements/arrows";

const TopicTab = ({ currentTopic, selected, setSelected, topic, setSelectedIndex }) => {
  return (
    <button
      name={currentTopic}
      className={classNames("slider-faq__topic", {
        "slider-faq__topic--active": selected === currentTopic,
      })}
      onClick={() => {
        setSelected(currentTopic);
        setSelectedIndex(topic?.findIndex((element) => element?.value === currentTopic));
      }}
    >
      {currentTopic}
    </button>
  );
};

const TopicQuickAction = ({
  currentTopic,
  selected,
  setSelected,
  topic,
  setSelectedIndex,
  sliderRef,
  currentSlide,
  slidesToShow,
}) => {
  return (
    <button
      name={currentTopic}
      className={classNames("slider-faq__topic-quick", {
        "slider-faq__topic-quick--active": selected === currentTopic,
      })}
      onClick={() => {
        setSelected(currentTopic);
        let index = topic?.findIndex((element) => element?.value === currentTopic);
        setSelectedIndex(index);
        if (index < currentSlide || index > currentSlide + slidesToShow - 1) {
          sliderRef.current.slickGoTo(index - (slidesToShow - 1));
        }
      }}
    >
      {currentTopic}
    </button>
  );
};

const SliderFaq = ({ data }) => {
  const { title, subtitle, topic, coloreSfondo } = data;

  let slidesToShow = 4;
  const sliderRef = useRef();
  const [selected, setSelected] = useState(topic?.[0]?.value);
  const [selectedIndex, setSelectedIndex] = useState(
    topic.findIndex((element) => element?.value === selected)
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const [width, setWidth] = useState(1300);

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  if (width <= 575) {
    slidesToShow = 1;
  } else if (width > 575 && width <= 767) {
    slidesToShow = 2;
  } else if (width > 767 && width <= 1200) {
    slidesToShow = 3;
  } else {
    slidesToShow = 4;
  }

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    arrows: true,
    autoplay: false,
    prevArrow: <PrevArrow showPrevArrow={false} />,
    nextArrow: <NextArrow showNextArrow={false} slidesToShow={slidesToShow} />,
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: slidesToShow,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: slidesToShow,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: slidesToShow,
        },
      },
    ],
  };

  return (
    <section className="section slider-faq pt-4" style={{ backgroundColor: coloreSfondo?.value }}>
      <div className="wrapper-container">
        <div className="container-fluid">
          {title?.value && (
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="section__title" style={{ color: title?.content?.colorTitle?.value }}>
                  {title.value}
                </h2>
              </div>
            </div>
          )}
          {topic?.length > 0 && (
            <nav className="slider-faq__quick-action">
              {topic
                .filter((element) => element.content.quickAction.value)
                .map((item, index) => (
                  <TopicQuickAction
                    currentTopic={item?.value}
                    selected={selected}
                    setSelected={setSelected}
                    topic={topic}
                    setSelectedIndex={setSelectedIndex}
                    sliderRef={sliderRef}
                    currentSlide={currentSlide}
                    slidesToShow={slidesToShow}
                    key={index}
                  />
                ))}
            </nav>
          )}
          {topic?.length > 0 && (
            <nav className="slider-faq__slider">
              <Slider {...settings} ref={sliderRef}>
                {topic.map((item, index) => (
                  <TopicTab
                    currentTopic={item?.value}
                    selected={selected}
                    setSelected={setSelected}
                    topic={topic}
                    setSelectedIndex={setSelectedIndex}
                    key={index}
                  />
                ))}
              </Slider>
            </nav>
          )}
          {subtitle?.value && (
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="slider-faq__subtitle">
                  {subtitle.value} {selected}
                </h2>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="accordions">
                <ul className="accordions__list">
                  {topic[selectedIndex].content.sectionTitle.map((item, index) => (
                    <AccordionFaq key={`${selectedIndex}-${index}`} accordion={item} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(SliderFaq);
export const fragment = graphql`
  fragment JskSliderFaqFragment on LiferayJskSliderFaq {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
      content {
        colorTitle {
          value
        }
      }
    }
    subtitle {
      value
    }
    topic {
      value
      content {
        quickAction {
          value
        }
        sectionTitle {
          value
          content {
            colorSectionTitle {
              value
            }
            content {
              value
            }
          }
        }
      }
    }
    coloreSfondo {
      value
    }
  }
`;
