import React, { useRef, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import withPreview from "../../utility/with-preview";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Input from "../elements/input";
import PrivacyModal from "../fasce/privacy-modal";
import { FormPrivacy } from "../privacy-texts";
import { submitFormAutolettura } from "../../utility/config-form-autolettura";
import { overlayTextParser } from "../../utility/config-form-contatti";
import Cta from "../elements/cta";
import Icon from "../elements/icon-svg";

const FormAutolettura = ({ data }) => {
  const {
    titleText,
    image,
    introText,
    flagPrivacyText,
    ctaText,
    linkText,
    thankYouPageTitle,
    thankYouPageSubtitle,
    cta,
  } = data;

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({ mode: "onTouched" });

  const [response, setResponse] = useState("");
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [formValues, setFormValues] = useState();

  const responseRef = useRef();

  const { executeRecaptcha } = useGoogleReCaptcha();

  let today = new Date();
  let date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let dateTime = date + " " + time;

  const submit = async (values) => {
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("formCasa");
    try {
      const result = await submitFormAutolettura({
        ...values,
        grecaptcha: token,
        privacyCheck: flagPrivacyText?.value ? true : false,
      });
      console.log("Submit result: ", result);
      setResponse("ok");
      setFormValues(values);
    } catch (err) {
      console.error("Error submit: ", err);
      setResponse("ko");
    }
    if (responseRef.current) {
      responseRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  };

  return (
    <>
      <span ref={responseRef} className="scroll-anchor" id="FormAutoletturaAnchor" />
      <section className="section form-autolettura section--lightblue section--style">
        <div className="container-fluid wrapper-container px-3">
          {response === "" && (
            <>
              {titleText?.value && (
                <div className="row">
                  <div className="col-12 text-center">
                    <h2 className="section__title section__title--banded form-autolettura__title">
                      {titleText.value}
                    </h2>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-9">
                  <form
                    className="form form-autolettura__form form-autolettura__form--23 pt-0"
                    onSubmit={handleSubmit(submit)}
                  >
                    {introText?.value && (
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="form__title"
                            dangerouslySetInnerHTML={{ __html: introText.value }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row mb-4">
                      <div className="form-autolettura__fields col-md-6 mb-4 mb-md-0">
                        <Input
                          name="codiceCliente"
                          register={register}
                          validation={{
                            required: {
                              value: true,
                              message: "Per favore compila questo campo per inviare l'autolettura.",
                            },
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Specificare codice cliente valido.",
                            },
                            minLength: {
                              value: 13,
                              message: "Specificare codice cliente valido.",
                            },
                            maxLength: {
                              value: 13,
                              message: "Specificare codice cliente valido.",
                            },
                          }}
                          tip="Trovi il tuo codice cliente in alto a destra nella prima pagina della tua bolletta. Inseriscilo senza spazi"
                          placeholder="Codice cliente"
                          movingLabel={false}
                          iconName="user"
                          error={errors.codiceCliente}
                          touched={touchedFields.codiceCliente}
                          check={true}
                          checkError={true}
                          minLength="13"
                          maxLength="13"
                        />
                        <div className="form-autolettura__tooltip active d-none d-md-block">
                          <span className="form-autolettura__tooltip-label">i</span>
                          <div className="form-autolettura__tooltip-text">
                            Trovi il tuo codice cliente in alto a destra nella prima pagina della
                            tua bolletta. Inseriscilo senza spazi
                          </div>
                        </div>
                      </div>
                      <div className="form-autolettura__fields col-md-6">
                        <Input
                          name="codiceFiscale"
                          register={register}
                          validation={{
                            required: {
                              value: true,
                              message: "Per favore compila questo campo per inviare l'autolettura.",
                            },
                            pattern: {
                              value:
                                /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/i,
                              message: "Specificare codice fiscale valido.",
                            },
                            minLength: {
                              value: 16,
                              message: "Specificare codice fiscale valido.",
                            },
                            maxLength: {
                              value: 16,
                              message: "Specificare codice fiscale valido.",
                            },
                          }}
                          tip="Inserisci il codice fiscale a cui è intestata la fornitura gas. Lo puoi trovare in bolletta nel riquadro giallo Fornitura Gas"
                          placeholder="Codice fiscale"
                          movingLabel={false}
                          iconName="address-card"
                          error={errors.codiceFiscale}
                          touched={touchedFields.codiceFiscale}
                          check={true}
                          checkError={true}
                          minLength="16"
                          maxLength="16"
                        />
                        <div className="form-autolettura__tooltip active d-none d-md-block">
                          <span className="form-autolettura__tooltip-label">i</span>
                          <div className="form-autolettura__tooltip-text">
                            Inserisci il codice fiscale a cui è intestata la fornitura gas. Lo puoi
                            trovare in bolletta nel riquadro giallo Fornitura Gas
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="form-autolettura__fields col-md-6">
                        <Input
                          name="autoLettura"
                          register={register}
                          validation={{
                            required: {
                              value: true,
                              message: "Per favore compila questo campo per inviare l'autolettura.",
                            },
                            pattern: {
                              value: /^([1-9]{1}[0-9]+)$/i,
                              message:
                                "Specificare autolettura valida. Verifica che il valore inserito non inizi con 0.",
                            },
                            minLength: {
                              value: 1,
                              message: "Specificare autolettura valida.",
                            },
                            maxLength: {
                              value: 9,
                              message: "Specificare autolettura valida.",
                            },
                          }}
                          tip="Riporta le cifre situate a sinistra della virgola escludendo gli zero iniziali"
                          placeholder="Autolettura"
                          movingLabel={false}
                          iconName="input-numeric"
                          error={errors.autoLettura}
                          touched={touchedFields.autoLettura}
                          check={true}
                          checkError={true}
                          minLength="1"
                          maxLength="9"
                        />
                        <div className="form-autolettura__tooltip active d-none d-md-block">
                          <span className="form-autolettura__tooltip-label">i</span>
                          <div className="form-autolettura__tooltip-text">
                            Riporta le cifre situate a sinistra della virgola escludendo gli zero
                            iniziali
                          </div>
                        </div>
                      </div>
                    </div>
                    {flagPrivacyText?.value && (
                      <div className="row mb-4">
                        <div className="col-12">
                          <Input
                            type="checkbox"
                            name="personalData"
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Per favore leggi e acconsenti alle norme sulla privacy.",
                              },
                            }}
                            label={overlayTextParser(flagPrivacyText.value, setShowPrivacyModal)}
                            error={errors.personalData}
                          />
                        </div>
                      </div>
                    )}
                    {ctaText?.value && (
                      <div className="row mb-4">
                        <div className="col-12 text-center">
                          <button type="submit" className="form__submit">
                            {ctaText.value}
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                  {linkText?.value && (
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="form-autolettura__text-with-link"
                          dangerouslySetInnerHTML={{ __html: linkText.value }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {image?.node && (
                  <div className="d-none d-md-block col-md-3">
                    <GatsbyImage image={getImage(image.node)} alt="" />
                  </div>
                )}
              </div>
            </>
          )}
          {response && (
            <>
              {response === "ok"
                ? thankYouPageTitle?.value && (
                    <div className="row">
                      <div className="col-12 text-center">
                        <h2 className="section__title section__title--banded form-autolettura__title">
                          {thankYouPageTitle.value}
                        </h2>
                      </div>
                    </div>
                  )
                : titleText?.value && (
                    <div className="row">
                      <div className="col-12 text-center">
                        <h2 className="section__title section__title--banded form-autolettura__title">
                          {titleText.value}
                        </h2>
                      </div>
                    </div>
                  )}
              <div className="row">
                <div className="col-lg-8">
                  {response === "ok" ? (
                    thankYouPageSubtitle?.value ? (
                      <div
                        className="form-autolettura__response"
                        dangerouslySetInnerHTML={{
                          __html: thankYouPageSubtitle.value,
                        }}
                      />
                    ) : (
                      <div className="form-autolettura__response">
                        <p>
                          Per effettuare l'autolettura più velocemente e godere di tutti i vantaggi
                          di ENGIE, registrati allo Spazio Clienti
                        </p>
                      </div>
                    )
                  ) : (
                    <>
                      <div className="form-autolettura__response">
                        <p>Ci spiace, i dati inseriti non sono corretti.</p>
                        <div className="row mb-4">
                          <div className="col-md-12 form-autolettura__retry">
                            <button onClick={() => setResponse("")}>
                              Controllali e riprova ad effettuare l'autolettura cliccando qui.
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {response === "ok" && formValues && (
                    <>
                      {cta && <Cta cta={cta} className="form-autolettura__button" />}
                      <div className="form-autolettura__recap">
                        <span className="form-autolettura__recap-title">Registrandoti potrai:</span>
                        <div className="form-autolettura__recap-item">
                          <div className="form-autolettura__recap-icon-container">
                            <Icon name="check-blue" cls="form-autolettura__recap-icon" />
                          </div>
                          <div>
                            <span className="form-autolettura__recap-subtitle">
                              Fare l'autolettura in un click
                            </span>
                            <p className="form-autolettura__recap-text">
                              Inserisci solamente il dato dell'autolettura senza dover recuperare
                              codice cliente e codice fiscale
                            </p>
                          </div>
                        </div>
                        <div className="form-autolettura__recap-item">
                          <div className="form-autolettura__recap-icon-container">
                            <Icon name="check-blue" cls="form-autolettura__recap-icon" />
                          </div>
                          <div>
                            <span className="form-autolettura__recap-subtitle">
                              Gestire le tue forniture in piena autonomia
                            </span>
                            <p className="form-autolettura__recap-text">
                              Visualizza, scarica o richiedi un duplicato delle tue bollette,
                              visualizza lo stato dei tuoi pagamenti, attiva nuovi servizi e molto
                              altro ancora
                            </p>
                          </div>
                        </div>
                        <div className="form-autolettura__recap-item">
                          <div className="form-autolettura__recap-icon-container">
                            <Icon name="check-blue" cls="form-autolettura__recap-icon" />
                          </div>
                          <div>
                            <span className="form-autolettura__recap-subtitle">
                              Ricevere sconti in bolletta grazie a ENGIE Pulse
                            </span>
                            <p className="form-autolettura__recap-text">
                              Compi semplici azioni all'interno dello spazio clienti e raccogli
                              punti LED per ricevere sconti in bolletta
                            </p>
                          </div>
                        </div>
                      </div>
                      <a href="/in-evidenza/app/" className="form-autolettura__print-button">
                        Scopri tutti i vantaggi
                      </a>
                    </>
                  )}
                </div>
                {response === "ok" && formValues && (
                  <div className="col-lg-4">
                    <div className="form-autolettura__card">
                      <div className="form-autolettura__card-title">La tua autolettura</div>
                      <div className="form-autolettura__card-items">
                        <div className="form-autolettura__item pb-3 mb-3">
                          <span className="form-autolettura__label">Data</span>
                          <span className="form-autolettura__value">{dateTime}</span>
                        </div>
                        <div className="form-autolettura__item pb-3 mb-3">
                          <span className="form-autolettura__label">Codice cliente</span>
                          <span className="form-autolettura__value">
                            {formValues.codiceCliente}
                          </span>
                        </div>
                        <div className="form-autolettura__item pb-3 mb-3">
                          <span className="form-autolettura__label">CF</span>
                          <span className="form-autolettura__value">
                            {formValues.codiceFiscale}
                          </span>
                        </div>
                        <div className="form-autolettura__item pb-3 mb-3">
                          <span className="form-autolettura__label">Autolettura</span>
                          <span className="form-autolettura__value">{formValues.autoLettura}</span>
                        </div>
                      </div>
                      <div className="form-autolettura__text-with-link">
                        <p>
                          <button onClick={window.print} className="form-autolettura__print-button">
                            Stampa la tua autolettura
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {showPrivacyModal && (
            <PrivacyModal
              show={showPrivacyModal}
              setShow={setShowPrivacyModal}
              scrollbar={true}
              modalStyle={{
                top: "0px",
                bottom: "0px",
                maxWidth: "unset",
              }}
            >
              <FormPrivacy
                innerContent={flagPrivacyText.content.privacyBodyText.value}
                setShow={setShowPrivacyModal}
                showCloseButton={true}
              />
            </PrivacyModal>
          )}
        </div>
      </section>
    </>
  );
};

export default withPreview(FormAutolettura);
export const fragment = graphql`
  fragment JskFormAutoletturaFragment on LiferayJskFormAutolettura {
    liferayFields {
      siteId
      articleId
    }
    titleText {
      value
    }
    image {
      node {
        gatsbyImageData(width: 326)
      }
    }
    introText {
      value
    }
    flagPrivacyText {
      value
      content {
        privacyBodyText {
          value
        }
      }
    }
    ctaText {
      value
    }
    linkText {
      value
    }
    thankYouPageTitle {
      value
    }
    thankYouPageSubtitle {
      value
    }
    cta: ctaThankYouPage {
      value
      content {
        ctaLink: linkThankYouPage {
          value
        }
      }
    }
  }
`;
