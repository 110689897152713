import React, { useState } from "react";
import classNames from "classnames";

const AccordionFaq = ({ accordion }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <li className="accordions__item">
      <button
        className="accordions__title"
        style={{ color: accordion.content?.colorSectionTitle?.value }}
        onClick={() => setOpen(!isOpen)}
      >
        <span
          className={classNames("accordions__icon", {
            "accordions__icon--open": isOpen,
          })}
        ></span>
        <span>{accordion.value}</span>
      </button>
      <div
        className={classNames("accordions__content", {
          "accordions__content--expanded": isOpen,
        })}
      >
        <div dangerouslySetInnerHTML={{ __html: accordion?.content?.content?.value }}></div>
      </div>
    </li>
  );
};

export default AccordionFaq;
