import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import Disclosure from "../elements/disclosure";

const AccordionList = ({ data }) => {
  const { title, firstSection } = data;

  return (
    <section className="section accordion-list wrapper-container px-0">
      <div className="row mx-0">
        <div className="col-md-12 align-center">
          {title?.value && <h2 className="section__title">{title.value}</h2>}
        </div>
      </div>
      <div className="row mx-0 justify-content-center">
        <div className="col-md-12">
          <ul className="accordion-list__list">
            {firstSection?.map((firstDisclosure, i) => (
              <Disclosure disclosure={firstDisclosure} key={i} />
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default withPreview(AccordionList);
export const fragment = graphql`
  fragment JskAccordionListFragment on LiferayJskAccordionList {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    firstSection {
      value
      content {
        body: firstBody {
          value
        }
        secondSection {
          value
          content {
            body: secondBody {
              value
            }
          }
        }
      }
    }
  }
`;
