import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";

const GradientHero = ({ data }) => {
  const { gradient, title } = data;
  return (
    <section className="section gradient-hero" style={{ background: gradient?.value }}>
      <div className="container-fluid">
        {title?.value && <h1 className="gradient-hero__title">{title.value}</h1>}
      </div>
    </section>
  );
};

export default withPreview(GradientHero);
export const fragment = graphql`
  fragment JskGradientHeroFragment on LiferayJskGradientHero {
    liferayFields {
      siteId
      articleId
    }
    gradient {
      value
    }
    title {
      value
    }
  }
`;
