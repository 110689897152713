import React, { useEffect } from "react";
import classNames from "classnames";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Link } from "../link";
import Icon from "../elements/icon-svg";
import withPreview from "../../utility/with-preview";
import { dataLayerStr2Obj, dataLayerPush } from "../../utility/dataLayerUtils";
import { handleContentAccessibility } from "../../utility/accessibility";

const CallAction3Items = ({ data }) => {
  const {
    model,
    mainTitle,
    titolo1,
    sottotitolo1,
    ctaText1,
    immagine1,
    immagine1Mobile,
    pageLink1,
    datalayerPromotion1,
    titolo2,
    sottotitolo2,
    ctaText2,
    immagine2,
    immagine2Mobile,
    pageLink2,
    datalayerPromotion2,
    titolo3,
    sottotitolo3,
    ctaText3,
    immagine3,
    immagine3Mobile,
    pageLink3,
    datalayerPromotion3,
  } = data;

  const modello = model?.value?.[0] || "CallAction3ItemsHomeOfferte_lazy-img";

  const itemsData = [
    titolo1?.value !== "" && {
      immagine: immagine1,
      immagineMobile: immagine1Mobile,
      titolo: titolo1,
      sottotitolo: sottotitolo1,
      ctaText: ctaText1,
      link: pageLink1?.value?.link,
      promotionObj: dataLayerStr2Obj(datalayerPromotion1?.value),
    },
    titolo2?.value !== "" && {
      immagine: immagine2,
      immagineMobile: immagine2Mobile,
      titolo: titolo2,
      sottotitolo: sottotitolo2,
      ctaText: ctaText2,
      link: pageLink2?.value?.link,
      promotionObj: dataLayerStr2Obj(datalayerPromotion2?.value),
    },
    titolo3?.value !== "" && {
      immagine: immagine3,
      immagineMobile: immagine3Mobile,
      titolo: titolo3,
      sottotitolo: sottotitolo3,
      ctaText: ctaText3,
      link: pageLink3?.value?.link,
      promotionObj: dataLayerStr2Obj(datalayerPromotion3?.value),
    },
  ].filter(Boolean);

  useEffect(() => {
    dataLayerPush({
      event: "npromo 2",
      ecommerce: {
        promoView: {
          promotions: [
            dataLayerStr2Obj(datalayerPromotion1?.value),
            dataLayerStr2Obj(datalayerPromotion2?.value),
            dataLayerStr2Obj(datalayerPromotion3?.value),
          ],
        },
      },
    });
  }, [datalayerPromotion1, datalayerPromotion2, datalayerPromotion3]);

  const CallActionItem = ({
    immagine,
    immagineMobile,
    titolo,
    sottotitolo,
    ctaText,
    link,
    promotionObj,
  }) => {
    const image =
      modello === "CallAction3Items_lazy-img-secondo"
        ? immagine?.node
          ? withArtDirection(
              getImage(immagine?.node),
              [
                immagineMobile?.node && {
                  media: "(max-width: 768px)",
                  image: getImage(immagineMobile.node),
                },
              ].filter(Boolean)
            )
          : null
        : getImage(immagine?.node);

    return (
      <Link
        to={link}
        className="call-action-3-items__box d-flex align-items-end"
        onClick={() =>
          dataLayerPush({
            event: "promotionClick",
            ecommerce: {
              promoClick: {
                promotions: [promotionObj],
              },
            },
          })
        }
      >
        {immagine?.node && (
          <GatsbyImage
            className="call-action-3-items__box-image"
            image={image}
            alt={titolo.value || ""}
          />
        )}
        <div className="call-action-3-items__text-wrapper align-self-end position-relative">
          <div className="call-action-3-items__box-title">
            {handleContentAccessibility(titolo.value)}
          </div>
          <div className="call-action-3-items__box-subtitle-wrapper">
            <h4
              className="call-action-3-items__box-subtitle mt-1"
              dangerouslySetInnerHTML={{ __html: sottotitolo.value }}
            ></h4>
            {ctaText?.value !== "" ? (
              <button className="call-action-3-items__btn-text mt-2" aria-label="Detail">
                {ctaText?.value && <span>{ctaText.value}</span>}
                <Icon
                  name="long-arrow-right-solid"
                  style={{ "--width": "31px", "--height": "30px" }}
                />
              </button>
            ) : (
              <button className="call-action-3-items__btn-more mt-2" aria-label="Detail">
                <Icon
                  name="long-arrow-right-solid"
                  style={{ "--width": "31px", "--height": "30px" }}
                />
              </button>
            )}
          </div>
        </div>
      </Link>
    );
  };

  return (
    <section
      className={classNames(
        "section call-action-3-items wrapper-container px-0",
        modello === "CallAction3Items_lazy-img-secondo" && "call-action-3-items--modello-secondo",
        modello === "CallAction3ItemsProdottiCorrelati" && "call-action-3-items--modello-correlati"
      )}
    >
      <div className="row mx-0">
        <div
          className={classNames(
            "col-md-12",
            mainTitle.content?.mainTitlePosition?.value || "align-center"
          )}
        >
          {/* <h2 className="section__title section__title--big"> */}
          <h2 className="section__title">
            {modello === "CallAction3ItemsProdottiCorrelati"
              ? "Prodotti correlati"
              : mainTitle.value
              ? mainTitle.value
              : "Le nostre migliori offerte"}
          </h2>
        </div>
      </div>
      <div className="row mx-0 justify-content-center">
        {itemsData?.map((itemData, i) => (
          <div
            className={classNames(
              "px-0",
              modello === "CallAction3Items_lazy-img-secondo"
                ? i === 0
                  ? "col-md-6"
                  : "col-md-3"
                : "col-md-4"
            )}
            key={i}
          >
            <CallActionItem {...itemData} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default withPreview(CallAction3Items);

export const fragment = graphql`
  fragment CallActionPageLinkFragment on LiferayWebContent {
    ... on LiferayJskLayoutPaginaProdotto {
      link
    }
    ... on LiferayJskLayoutPaginaHub {
      link
    }
    ... on LiferayJskLayoutPaginaGenerica {
      link
    }
    ... on LiferayJskLayoutLandingOfferte {
      link
    }
  }
  fragment CallAction3ItemsFragment on LiferayCallAction3Items {
    id
    liferayFields {
      siteId
      articleId
    }
    model {
      value
    }
    mainTitle {
      value
      content {
        mainTitlePosition {
          value
        }
      }
    }
    titolo1 {
      value
    }
    sottotitolo1 {
      value
    }
    ctaText1 {
      value
    }
    boxColor1 {
      value
    }
    immagine1 {
      node {
        publicURL
        gatsbyImageData(width: 772)
      }
    }
    immagine1Mobile {
      node {
        gatsbyImageData(width: 573)
      }
    }
    pageLink1 {
      value {
        ...CallActionPageLinkFragment
      }
    }
    datalayerPromotion1 {
      value
    }
    titolo2 {
      value
    }
    sottotitolo2 {
      value
    }
    ctaText2 {
      value
    }
    boxColor2 {
      value
    }
    immagine2 {
      node {
        publicURL
        gatsbyImageData(width: 772)
      }
    }
    immagine2Mobile {
      node {
        gatsbyImageData(width: 573)
      }
    }
    pageLink2 {
      value {
        ...CallActionPageLinkFragment
      }
    }
    datalayerPromotion2 {
      value
    }
    titolo3 {
      value
    }
    sottotitolo3 {
      value
    }
    ctaText3 {
      value
    }
    boxColor3 {
      value
    }
    immagine3 {
      node {
        publicURL
        gatsbyImageData(width: 772)
      }
    }
    immagine3Mobile {
      node {
        gatsbyImageData(width: 573)
      }
    }
    pageLink3 {
      value {
        ...CallActionPageLinkFragment
      }
    }
    datalayerPromotion3 {
      value
    }
  }
`;
