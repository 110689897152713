import classNames from "classnames";
import React from "react";
import Loadable from "@loadable/component";

const Modal = Loadable(() => import("react-overlays/Modal"));

const PrivacyModal = ({ show, setShow, scrollbar, modalStyle, children }) => {
  const renderBackdrop = (props) => (
    <button
      className="privacy-modal__backdrop"
      open={show}
      onClick={() => {
        setShow(false);
      }}
      {...props}
    />
  );

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className={classNames("privacy-modal", { "privacy-modal--scrollbar-hidden": !scrollbar })}
      renderBackdrop={renderBackdrop}
      style={modalStyle}
    >
      {children}
    </Modal>
  );
};

export default PrivacyModal;
