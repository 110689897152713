import React, { useState, useRef, useEffect, useCallback } from "react";
import throttle from "lodash.throttle";
import { graphql } from "gatsby";
import classNames from "classnames";
import withPreview from "../../utility/with-preview";
import Icon from "../elements/icon-svg";
import Disclosure from "../elements/disclosure";

const SingleSecondAccordionMobile = ({ disclosure, disclosureIndex }) => {
  const [openSecond, setOpenSecond] = useState(-1);

  return (
    <li className="slider-card__item slider-card__item--secondary">
      <button
        className="slider-card__title slider-card__title--secondary"
        onClick={() => setOpenSecond(openSecond === disclosureIndex ? -1 : disclosureIndex)}
      >
        {disclosure?.value && <span className="slider-card__disclosure">{disclosure.value}</span>}
        <Icon
          name={openSecond === disclosureIndex ? "chevron-up" : "chevron-down"}
          cls="slider-card__icon slider-card__icon--secondary"
        />
      </button>
      <div
        className={classNames("slider-card__content slider-card__content--secondary", {
          "slider-card__content--expanded": openSecond === disclosureIndex,
        })}
      >
        {disclosure?.content?.body?.value && (
          <div
            dangerouslySetInnerHTML={{
              __html: disclosure.content.body.value,
            }}
          />
        )}
      </div>
    </li>
  );
};

const SingleFirstAccordionMobile = ({ disclosure, disclosureIndex }) => {
  const [openFirst, setOpenFirst] = useState(-1);

  return (
    <li
      className={classNames("slider-card__item", {
        "slider-card__item--expanded": openFirst === disclosureIndex,
      })}
      style={{ borderInline: "unset" }}
    >
      <button
        className="slider-card__title"
        onClick={() => setOpenFirst(openFirst === disclosureIndex ? -1 : disclosureIndex)}
      >
        {disclosure?.value && <span className="slider-card__disclosure">{disclosure.value}</span>}
        <Icon
          name={openFirst === disclosureIndex ? "chevron-up-blue" : "chevron-down-blue"}
          cls="slider-card__icon"
        />
      </button>
      <div
        className={classNames("slider-card__content", {
          "slider-card__content--expanded": openFirst === disclosureIndex,
        })}
      >
        <ul className="accordion-list__list">
          {disclosure?.content?.sectionTitle?.map((secondDisclosure, j) => (
            <SingleSecondAccordionMobile
              disclosure={secondDisclosure}
              disclosureIndex={j}
              key={j}
            />
          ))}
        </ul>
      </div>
    </li>
  );
};

const AccordionSlider = ({ data }) => {
  const { title, topic } = data;

  const [selected, setSelected] = useState(topic?.[0]?.value);
  const [selectedIndex, setSelectedIndex] = useState(
    topic.findIndex((element) => element?.value === selected)
  );

  const scrollableRef = useRef();

  const slidePrev = useCallback(() => {
    scrollableRef.current.scrollTo({
      left: scrollableRef.current.scrollLeft - scrollableRef.current.clientWidth / 2,
      behavior: "smooth",
    });
  }, []);

  const slideNext = useCallback(() => {
    scrollableRef.current.scrollTo({
      left: scrollableRef.current.scrollLeft + scrollableRef.current.clientWidth / 2,
      behavior: "smooth",
    });
  }, []);

  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);

  useEffect(() => {
    const scrollableElem = scrollableRef.current;
    if (scrollableElem) {
      const listener = throttle(() => {
        setShowPrev(scrollableElem.scrollLeft > 0);
        setShowNext(
          scrollableElem.children[scrollableElem.children.length - 1].getBoundingClientRect()
            .right >
            scrollableElem.getBoundingClientRect().right + 10
        );
      }, 100);
      scrollableElem.addEventListener("scroll", listener);
      window.addEventListener("resize", listener);
      listener();
      return () => {
        scrollableElem.removeEventListener("scroll", listener);
        window.removeEventListener("resize", listener);
      };
    }
  }, []);

  return (
    <section className="section accordion-slider pt-4">
      <div className="wrapper-container">
        <div className="container-fluid">
          {title?.value && (
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="section__title">{title.value}</h2>
              </div>
            </div>
          )}
          <div className="d-none d-md-block">
            <div className="slider-card pt-0">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 px-0">
                    <div className="slider-card__slider">
                      <button
                        className="slider-card__arrow slider-card__arrow--prev"
                        aria-label="prev"
                        disabled={!showPrev}
                        onClick={slidePrev}
                      />
                      <div ref={scrollableRef} className="slider-card__scrollable">
                        {topic?.map((tab, index) => (
                          <div className="slider-card__tab" key={index}>
                            <button
                              name={index}
                              className={classNames("slider-card__inner", {
                                "slider-card__inner--active": selected === tab.value,
                              })}
                              onClick={() => {
                                setSelected(tab.value);
                                setSelectedIndex(
                                  topic?.findIndex((element) => element?.value === tab.value)
                                );
                              }}
                            >
                              {tab?.value && <span>{tab.value}</span>}
                            </button>
                          </div>
                        ))}
                      </div>
                      <button
                        className="slider-card__arrow slider-card__arrow--next"
                        aria-label="next"
                        disabled={!showNext}
                        onClick={slideNext}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="accordion-list">
                  <ul className="accordion-list__list">
                    {topic?.[selectedIndex]?.content?.sectionTitle?.map((disclosure, i) => (
                      <Disclosure disclosure={disclosure} key={`${selectedIndex}-${i}`} />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="d-md-none d-block">
            <div className="slider-card__accordion pt-0">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 px-0">
                    <ul className="slider-card__list">
                      {topic?.map((disclosure, i) => (
                        <SingleFirstAccordionMobile
                          disclosure={disclosure}
                          disclosureIndex={i}
                          key={i}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(AccordionSlider);
export const fragment = graphql`
  fragment JskAccordionSliderFragment on LiferayJskAccordionSlider {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    topic {
      value
      content {
        sectionTitle {
          value
          content {
            body {
              value
            }
          }
        }
      }
    }
  }
`;
