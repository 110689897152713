import React, { useMemo, useCallback, useEffect, useRef } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { getImage, withArtDirection } from "gatsby-plugin-image";
import SlideHeaderContent from "../elements/slide-header-content";
import withPreview from "../../utility/with-preview";
import { dataLayerPush } from "../../utility/dataLayerUtils";

const LinkWrapper = ({ cta, children, dataLayerEvent, ...other }) => {
  const hasLink = cta?.value && cta?.content?.ctaLink?.value;
  const ref = useRef();
  const onClick = useCallback(
    (e) => {
      const linkElem = ref.current?.querySelector(`a[href="${cta.content.ctaLink.value}"]`);
      if (linkElem && !linkElem.contains(e.target)) {
        dataLayerPush(dataLayerEvent);
        navigate(cta.content.ctaLink.value);
      }
    },
    [cta, dataLayerEvent]
  );
  return hasLink ? (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
    <div ref={ref} role="button" {...other} onClick={onClick}>
      {children}
    </div>
  ) : (
    <div {...other}>{children}</div>
  );
};

const SliderHeaderHomeV2 = ({ data }) => {
  const { data: staticData } = useStaticQuery(graphql`
    {
      data: liferaySliderHeaderHomeV2(liferayFields: { articleId: { eq: "5765201" } }) {
        ...SliderHeaderHomeV2Fragment
      }
    }
  `);
  const { slide, cta, ctaNew } = data || staticData;
  const {
    desktopslideimg,
    tabletslideimg,
    smartphoneslideimg,
    producttitle,
    productdescription,
    desktopproductimage,
    smartphoneproductimage,
    gtmproductname,
    gtmproductcode,
  } = slide?.content || {};

  const headerImage = withArtDirection(
    getImage(desktopslideimg.node),
    [
      smartphoneslideimg?.node && {
        media: "(max-width: 768px)",
        image: getImage(smartphoneslideimg.node),
      },
      tabletslideimg?.node && {
        media: "(max-width: 992px)",
        image: getImage(tabletslideimg.node),
      },
    ].filter(Boolean)
  );
  const productImage = desktopproductimage?.node
    ? withArtDirection(
        getImage(desktopproductimage.node),
        [
          smartphoneproductimage?.node && {
            media: "(max-width: 768px)",
            image: getImage(smartphoneproductimage.node),
          },
        ].filter(Boolean)
      )
    : null;

  const promoObj = useMemo(() => {
    return {
      promotions: [
        {
          id: gtmproductcode?.value,
          name: gtmproductname?.value,
          creative: "carousel",
          position: "1",
        },
      ],
    };
  }, [gtmproductcode, gtmproductname]);

  useEffect(() => {
    dataLayerPush({
      event: "npromo 1",
      ecommerce: {
        promoView: promoObj,
      },
    });
  }, [promoObj]);

  return (
    <LinkWrapper
      cta={cta}
      className="slider-header-home-v2 position-relative mb-md-5"
      dataLayerEvent={{
        event: "promotionClick",
        ecommerce: {
          promoClick: promoObj,
        },
      }}
    >
      <SlideHeaderContent
        headerImg={headerImage}
        headerRatio={smartphoneslideimg?.node?.probeInfo?.aspectRatio}
        headerImgStyle={{ height: "590px" }}
        title={producttitle?.value}
        description={productdescription?.value}
        productImage={{ image: productImage, descr: desktopproductimage?.content?.description }}
        cta={cta}
        arrowScroller={{ value: true, scroll: { top: 590, left: 0, behavior: "smooth" } }}
        ctaList={ctaNew}
      />
    </LinkWrapper>
  );
};

export default withPreview(SliderHeaderHomeV2);
export const fragment = graphql`
  fragment SliderHeaderHomeV2Fragment on LiferaySliderHeaderHomeV2 {
    liferayFields {
      siteId
      articleId
    }
    slide {
      value
      content {
        productkey {
          value
        }
        producttitle {
          value
        }
        productdescription {
          value
        }
        desktopslideimg {
          node {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        tabletslideimg {
          node {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        smartphoneslideimg {
          node {
            gatsbyImageData(layout: FULL_WIDTH)
            probeInfo {
              aspectRatio
            }
          }
        }
        desktopproductimage {
          node {
            gatsbyImageData(width: 250, height: 80)
          }
          value {
            description
          }
        }
        smartphoneproductimage {
          node {
            publicURL
          }
        }
        gtmproductname {
          value
        }
        gtmproductpage {
          value
        }
        gtmproductcode {
          value
        }
      }
    }
    cta {
      value
      content {
        ctaLink {
          value
        }
        newTab {
          value
        }
        isBold {
          value
        }
      }
    }
    ctaNew {
      content {
        coloreCtaNew {
          value
        }
        coloreCtaNewClick {
          value
        }
        coloreCtaNewHover {
          value
        }
        linkCtaNew {
          value
        }
        tipoCtaNew {
          value
        }
      }
      value
    }
  }
`;
