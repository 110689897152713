import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";
import classNames from "classnames";

const BannerCta = ({ data }) => {
  const { imageDesktop, imageTablet, imageMobile, text, ctaText } = data;

  const bannerImg = imageDesktop?.node
    ? withArtDirection(
        getImage(imageDesktop?.node),
        [
          imageMobile?.node && {
            media: "(max-width: 767px)",
            image: getImage(imageMobile.node),
          },
          imageTablet?.node && {
            media: "(max-width: 991px)",
            image: getImage(imageTablet.node),
          },
        ].filter(Boolean)
      )
    : null;

  return (
    <section className="section banner-cta wrapper-container px-0">
      <div className="row mx-0 justify-content-center">
        <div className="col-md-12">
          <div className="banner-cta__container">
            {bannerImg && (
              <GatsbyImage
                image={bannerImg}
                className="banner-cta__image"
                alt={bannerImg?.value?.description || ""}
              />
            )}
            {text?.value && (
              <div dangerouslySetInnerHTML={{ __html: text.value }} className="banner-cta__text" />
            )}
            <Link
              to={ctaText?.content?.link?.value}
              title={ctaText?.content?.link?.value}
              className={classNames(
                "cta-link w-100 px-4 py-2 text-center text-nowrap my-1 my-md-0 banner-cta__cta",
                { secondary: ctaText?.content?.color?.value?.[0] === "white" }
              )}
            >
              {ctaText?.value && <span>{ctaText.value}</span>}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(BannerCta);
export const fragment = graphql`
  fragment JskBannerCtaFragment on LiferayJskBannerCta {
    liferayFields {
      siteId
      articleId
    }
    imageDesktop {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      value {
        description
      }
    }
    imageTablet {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      value {
        description
      }
    }
    imageMobile {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      value {
        description
      }
    }
    text {
      value
    }
    ctaText {
      value
      content {
        link {
          value
        }
        color {
          value
        }
      }
    }
  }
`;
