import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "../link";

const CardToPage = ({ cards }) => {
  return (
    <div className="row mx-0 justify-content-center">
      <div className="col-md-12">
        <div className="link-card__container">
          {cards?.map((item, key) => (
            <Link
              key={key}
              className="link-card__item"
              to={item?.content?.page?.value?.link || item?.content?.link?.value}
              title={item?.value}
            >
              {item?.content?.cardImage && (
                <GatsbyImage
                  image={getImage(item.content.cardImage.node)}
                  alt={item.content?.cardImage?.value?.description || ""}
                  className="link-card__image"
                />
              )}
              {item?.value && <div className="link-card__label">{item.value}</div>}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardToPage;
