import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import withPreview from "../../utility/with-preview";

const PhoneBox = ({ data }) => {
  const { text, label } = data;
  return (
    <section className="section phone-box wrapper-container px-0">
      <div className="row mx-0 justify-content-center">
        <div className="col-md-12">
          <div className="phone-box__container">
            {text?.value && (
              <div className="phone-box__title" dangerouslySetInnerHTML={{ __html: text.value }} />
            )}
            <div className="phone-box__block">
              {label?.map((item, key) => (
                <div key={key}>
                  {item?.value && (
                    <div
                      className="phone-box__text"
                      dangerouslySetInnerHTML={{ __html: item.value }}
                    />
                  )}
                  <div className="phone-box__number">
                    <GatsbyImage
                      image={getImage(item?.content?.icon?.node)}
                      alt={item.content?.value?.description || ""}
                    />
                    {item?.content?.number?.value && (
                      <span className="phone-box__telephone">{item.content.number.value}</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(PhoneBox);
export const fragment = graphql`
  fragment JskPhoneBoxFragment on LiferayJskPhoneBox {
    liferayFields {
      siteId
      articleId
    }
    text {
      value
    }
    label {
      value
      content {
        number {
          value
        }
        icon {
          node {
            gatsbyImageData
          }
          value {
            description
          }
        }
      }
    }
  }
`;
