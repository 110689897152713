import React, { useCallback } from "react";
import { graphql, navigate } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import withPreview from "../../utility/with-preview";
import { useJsApiLoader } from "@react-google-maps/api";
import HubSearchBar from "../elements/hub-search-bar";

const libraries = ["geometry", "places"];

const HubCentriAssistenza = ({ data }) => {
  const { moduleTitle, descriptionTitle, imageXl, imageL, imageTablet, imageMobile } = data;

  const headerImg = withArtDirection(
    getImage(imageXl?.node || imageL?.node),
    [
      imageMobile?.node && {
        media: "(max-width: 768px)",
        image: getImage(imageMobile.node),
      },
      imageTablet?.node && {
        media: "(max-width: 992px)",
        image: getImage(imageTablet.node),
      },
      imageL?.node && {
        media: "(max-width: 1200px)",
        image: getImage(imageL.node),
      },
    ].filter(Boolean)
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  const search = useCallback((address) => {
    if (address) {
      const params = new URLSearchParams();
      params.set("query", `${address}`);
      navigate(`/centri-assistenza/risultati/?${params.toString()}`);
    } else {
      console.warn("Missing address");
    }
  }, []);

  return (
    <>
      <section className="hub-centri-assistenza">
        {headerImg && (
          <div className="hub-centri-assistenza__image">
            <GatsbyImage image={headerImg} alt={""} />
          </div>
        )}
        <div className="hub-centri-assistenza__box-container">
          <div className="hub-centri-assistenza__box">
            {moduleTitle?.value && (
              <h1 className="hub-centri-assistenza__title">{moduleTitle.value}</h1>
            )}
            {isLoaded && <HubSearchBar search={search} />}
          </div>
        </div>
      </section>
      <section className="hub-centri-assistenza__body container">
        {descriptionTitle?.value && (
          <h2 className="hub-centri-assistenza__bodyTitle">{descriptionTitle.value}</h2>
        )}
        {descriptionTitle?.content?.descriptionBody?.value && (
          <div
            className="hub-centri-assistenza__bodyText"
            dangerouslySetInnerHTML={{ __html: descriptionTitle.content.descriptionBody.value }}
          ></div>
        )}
      </section>
    </>
  );
};

export default withPreview(HubCentriAssistenza);
export const fragment = graphql`
  fragment JskHubCentriAssistenzaFragment on LiferayJskHubCentriAssistenza {
    liferayFields {
      siteId
      articleId
    }
    moduleTitle {
      value
    }
    descriptionTitle {
      value
      content {
        descriptionBody {
          value
        }
      }
    }
    imageXl {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      value {
        description
      }
    }
    imageL {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      value {
        description
      }
    }
    imageTablet {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      value {
        description
      }
    }
    imageMobile {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      value {
        description
      }
    }
  }
`;
