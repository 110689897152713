import React, { useState, useRef, useEffect, useCallback } from "react";
import throttle from "lodash.throttle";
import { graphql } from "gatsby";
import classNames from "classnames";
import withPreview from "../../utility/with-preview";
import CardToPage from "../elements/card-to-page";
import Icon from "../elements/icon-svg";

const SingleCardAccordion = ({ tab, tabIndex, topic }) => {
  const [open, setOpen] = useState(-1);
  return (
    <li
      className={classNames("slider-card__item", {
        "slider-card__item--expanded": open === tabIndex,
      })}
    >
      <button
        className="slider-card__title"
        onClick={() => setOpen(open === tabIndex ? -1 : tabIndex)}
      >
        {tab?.value && <span className="slider-card__disclosure">{tab.value}</span>}
        <Icon
          name={open === tabIndex ? "chevron-up-blue" : "chevron-down-blue"}
          cls="slider-card__icon"
        />
      </button>
      <div
        className={classNames("slider-card__content", {
          "slider-card__content--expanded": open === tabIndex,
        })}
      >
        <CardToPage cards={topic?.[open]?.content?.cardTitle} />
      </div>
    </li>
  );
};

const SliderCard = ({ data }) => {
  const { title, topic } = data;

  const scrollableRef = useRef();

  const [selected, setSelected] = useState(topic?.[0]?.value);
  const [selectedIndex, setSelectedIndex] = useState(
    topic.findIndex((element) => element?.value === selected)
  );

  const slidePrev = useCallback(() => {
    scrollableRef.current.scrollTo({
      left: scrollableRef.current.scrollLeft - scrollableRef.current.clientWidth / 2,
      behavior: "smooth",
    });
  }, []);

  const slideNext = useCallback(() => {
    scrollableRef.current.scrollTo({
      left: scrollableRef.current.scrollLeft + scrollableRef.current.clientWidth / 2,
      behavior: "smooth",
    });
  }, []);

  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);

  useEffect(() => {
    const scrollableElem = scrollableRef.current;
    if (scrollableElem) {
      const listener = throttle(() => {
        setShowPrev(scrollableElem.scrollLeft > 0);
        setShowNext(
          scrollableElem.children[scrollableElem.children.length - 1].getBoundingClientRect()
            .right >
            scrollableElem.getBoundingClientRect().right + 10
        );
      }, 100);
      scrollableElem.addEventListener("scroll", listener);
      window.addEventListener("resize", listener);
      listener();
      return () => {
        scrollableElem.removeEventListener("scroll", listener);
        window.removeEventListener("resize", listener);
      };
    }
  }, []);

  return (
    <section className="section pt-4">
      <div className="wrapper-container">
        <div className="container-fluid">
          {title?.value && (
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="section__title">{title.value}</h2>
              </div>
            </div>
          )}
          <div className="d-none d-md-block">
            <div className="slider-card pt-0">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 px-0">
                    <div className="slider-card__slider">
                      <button
                        className="slider-card__arrow slider-card__arrow--prev"
                        aria-label="prev"
                        disabled={!showPrev}
                        onClick={slidePrev}
                      />
                      <div ref={scrollableRef} className="slider-card__scrollable">
                        {topic?.map((tab, index) => (
                          <div className="slider-card__tab" key={index}>
                            <button
                              name={index}
                              className={classNames("slider-card__inner", {
                                "slider-card__inner--active": selected === tab.value,
                              })}
                              onClick={() => {
                                setSelected(tab.value);
                                setSelectedIndex(
                                  topic?.findIndex((element) => element?.value === tab.value)
                                );
                              }}
                            >
                              {tab?.value && <span>{tab.value}</span>}
                            </button>
                          </div>
                        ))}
                      </div>
                      <button
                        className="slider-card__arrow slider-card__arrow--next"
                        aria-label="next"
                        disabled={!showNext}
                        onClick={slideNext}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CardToPage cards={topic?.[selectedIndex]?.content?.cardTitle} />
          </div>
          <div className="d-md-none d-block">
            <div className="slider-card__accordion pt-0">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 px-0">
                    <ul className="slider-card__list">
                      {topic?.map((tab, i) => (
                        <SingleCardAccordion tab={tab} tabIndex={i} topic={topic} key={i} />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(SliderCard);
export const fragment = graphql`
  fragment SliderCardPageLinkFragment on LiferayWebContent {
    ... on LiferayJskLayoutPaginaProdotto {
      link
    }
    ... on LiferayJskLayoutPaginaHub {
      link
    }
    ... on LiferayJskLayoutPaginaGenerica {
      link
    }
    ... on LiferayJskLayoutLandingOfferte {
      link
    }
  }
  fragment JskSliderCardFragment on LiferayJskSliderCard {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    topic {
      value
      content {
        cardTitle {
          value
          content {
            cardImage {
              node {
                gatsbyImageData
              }
              value {
                description
              }
            }
            page {
              value {
                ...SliderCardPageLinkFragment
              }
            }
            link {
              value
            }
          }
        }
      }
    }
  }
`;
