import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";
import classNames from "classnames";

const LinkBox = ({ data }) => {
  const { title, model, box } = data;
  return (
    <section className="section link-box wrapper-container px-0">
      <div className="row mx-0">
        <div className="col-md-12 align-center">
          {title?.value && <h2 className="section__title">{title.value}</h2>}
        </div>
      </div>
      <div className="row mx-0 justify-content-center">
        <div className="col-md-12">
          <div className="link-box__container">
            {box?.map((item, key) => (
              <Link
                key={key}
                className={classNames("link-box__item", {
                  "link-box__item--horizontal": model?.value?.[0] === "horizontal",
                })}
                href={item?.content?.link?.value}
              >
                {model?.value?.[0] === "vertical" ? (
                  <>
                    <GatsbyImage
                      image={getImage(item?.content?.icon?.node)}
                      alt={item.content.icon.content?.value?.description || ""}
                      className="link-box__image"
                    />
                    {item?.content?.boxTitle?.value && (
                      <span className="link-box__label">{item.content.boxTitle.value}</span>
                    )}
                  </>
                ) : (
                  <>
                    <GatsbyImage
                      image={getImage(item?.content?.icon?.node)}
                      alt={item.content.icon.content?.value?.description || ""}
                      className="link-box__image"
                    />
                    <div>
                      {item?.content?.boxTitle?.value && (
                        <span className="link-box__label">{item.content.boxTitle.value}</span>
                      )}
                      {item?.content?.subtitle?.value && (
                        <span className="link-box__subtitle">{item.content.subtitle.value}</span>
                      )}
                    </div>
                  </>
                )}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(LinkBox);
export const fragment = graphql`
  fragment JskLinkBoxFragment on LiferayJskLinkBox {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    model {
      value
    }
    box {
      content {
        icon {
          node {
            gatsbyImageData
          }
          value {
            description
          }
        }
        boxTitle {
          value
        }
        subtitle {
          value
        }
        link {
          value
        }
      }
    }
  }
`;
