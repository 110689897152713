import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";

const RecensioniVerificateWidget = ({ data }) => {
  const { mostraWidget } = data;
  return (
    <section className="section">
      <div className="wrapper-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="section__title section__title--big">Cosa dicono di noi</h2>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12 text-center"
              dangerouslySetInnerHTML={{ __html: mostraWidget?.content?.iframe?.value }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(RecensioniVerificateWidget);
export const fragment = graphql`
  fragment RecensioniVerificateWidgetFragment on LiferayRecensioniVerificateWidget {
    liferayFields {
      siteId
      articleId
    }
    mostraWidget {
      value
      content {
        iframe {
          value
        }
      }
    }
  }
`;
